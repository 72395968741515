// Custom global scripts
jQuery(document).ready(function($) {
    'use strict';

    // Foundation Init
    $(document).foundation();




    // Preloader
    function createCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        createCookie(name,"",-1);
    }


    if (readCookie('preloader') != null) {
        //alert('cookie exists'); // don't show the preloader
    } else {
        jQuery("#preloader").removeClass('hide'); // hide the preloader
        createCookie('preloader','hide', 14); // set the cookie so the preloader is hidden next time
    }

    //eraseCookie('preloader'); // uncomment this line to remove the cookie every time when testing
    
    // fade in the letters
   jQuery(".preloader-path").each(function(index) {
        var el = jQuery(this);
        el.delay(index * 70);
        el.animate({
            opacity:1
        }, 700);
    });
   
    // fade in the subtitle
    setTimeout(function(){
        jQuery("#preloader-subtitle").addClass('is-active'); 
    }, 1300);

    // fade the letters out
    setTimeout(function(){
        jQuery("#preloader-subtitle").removeClass('is-active'); 
        jQuery(".preloader-path").each(function(index) {
            var el = jQuery(this);
            el.delay(index * 70);
            el.animate({
                opacity:0
            }, 700);
        });
    }, 3500);

    // fade out the preloader
    setTimeout(function(){
        jQuery("#preloader").addClass('fadeout'); // hide the preloader
    }, 5000);


    // hide the preloader
    setTimeout(function(){
        jQuery("#preloader").addClass('hide'); // hide the preloader
    }, 5500);

    /*setTimeout(function(){
        document.getElementById("preloader-farmers-cooks-ad").classList.add('animate'); // animate the preloader
    }, 2000);*/

    jQuery(document).ready(function() {
        jQuery('.company-boxes').foundation();
    });




    // Mobile nav toggle
    $('#nav-mobile-toggle').on('click', function() {
        $('.js-mobile-nav').slideToggle();
    });

    $('.js-carousel-slider').slick({
  		dots: true,
  		infinite: true,
        fade: true,
  		speed: 300,
  		slidesToShow: 1
  	});

    $('.js-earnings-slider').slick({
        dots: true,
        arrows: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    });

    // Select all links with hashes
    $('a[href*="#"]')
      // Remove links that don't actually link to anything
      .not('[href="#"]')
      .not('[href="#0"]')
      .click(function(event) {
        // On-page links
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
          && 
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000, function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) { // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              };
            });
          }
        }
      });


    // sort companies
    var $grid = $('.js-company-grid').isotope({
      itemSelector: '.js-box',
      layoutMode: 'fitRows',
      getSortData: {
        title: '[data-title]',
        date: '[data-date] parseInt'
      },
      sortAscending: {
        title: true,
        date: false
      }
    });

    // bind sort button click
    $('.js-sort-companies').on( 'click', 'button', function() {
      var sortValue = $(this).attr('data-sort-value');
      $grid.isotope({ sortBy: sortValue });
    });

    // change is-checked class on buttons
    $('.js-sort-companies').each( function( i, buttonGroup ) {
      var $buttonGroup = $( buttonGroup );
      $buttonGroup.on( 'click', 'button', function() {
        $buttonGroup.find('.is-active').removeClass('is-active');
        $( this ).addClass('is-active');
      });
    });

    // show all companies
    $('.company-show-hide-link').on( 'click', function() {
        $(this).parent().find('.company-show-hide-link').toggle();

        if($(this).hasClass('show-more-posts')) {
            $('.js-company-grid .js-box.hidden-box').addClass('is-visible');
            $grid.isotope();
        } else {
            $('.js-company-grid .js-box.hidden-box').removeClass('is-visible');
            $grid.isotope();
        }
    });



    // Company Ajax Load More
    /*var content = $('.ajax-posts');
    var loader = $('#load-more-posts');
    var ppp = 8;
    var offset = $('.ajax-posts').find('.box').length;
     
    loader.on( 'click', load_ajax_posts );
     
    function load_ajax_posts() {
        console.log("test");

        if (!(loader.hasClass('post-loading-loader') || loader.hasClass('post-no-more-posts'))) {

            $.ajax({
                type: 'POST',
                dataType: 'html',
                url: ajax_params.ajax_url,
                data: {
                    'ppp': ppp,
                    'offset': offset,
                    'action': 'ajax_load_more_posts'
                },
                beforeSend : function () {
                    loader.addClass('post-loading-loader');
                    $('.after-post-component').addClass('hidden');
                    $('.loading-animation').removeClass('hidden');
                },
                success: function (data) {
                    var data = $(data);
                    loader.parent().removeClass('loading');
                    if (data.length) {
                        var newElements = data.css({ opacity: 0 });
                        content.append(newElements);
                        newElements.animate({ opacity: 1 });
                        loader.removeClass('post-no-more-posts').removeClass('post-loading-loader');
                        $('.after-post-component').addClass('hidden');
                        var post_count = $('.post-list').find('.post-box').length;
                        if (post_count < offset) {
                            // do not show button
                        } else {
                            $('.load-more-posts').removeClass('hidden');
                        }
                    } else {
                        loader.removeClass('post-loading-loader').addClass('post-no-more-posts');
                        $('.after-post-component').addClass('hidden');
                        $('.no-more-message').removeClass('hidden');
                    }
                },
                error : function (errorResponse, textStatus, errorThrown) {
                    loader.html($.parseJSON(errorResponse.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
                    console.log(errorResponse);
                },
            });
        }
        offset += ppp;
        return false;
    }


    // Blog Filter
    var content = $('.ajax-posts');
    var loader = $('#load-more-posts');
    var select = $('.blog-category-select');
    var ppp = 9;
    var exclude = loader.attr('data-exclude');
     
    select.on( 'change', category_filter_ajax_posts );
     
    function category_filter_ajax_posts() {

        var cat = $(this).val();
        loader.attr('data-category', cat);
        content.html('');

        $.ajax({
            type: 'POST',
            dataType: 'html',
            url: ajax_params.ajax_url,
            data: {
                'cat': cat,
                'ppp': ppp,
                'offset': 0,
                'exclude': exclude,
                'action': 'ajax_load_more_posts'
            },
            beforeSend : function () {
                loader.addClass('post-loading-loader');
                $('.after-post-component').addClass('hidden');
                $('.loading-animation').removeClass('hidden');
            },
            success: function (data) {
                var data = $(data);
                loader.parent().removeClass('loading');
                if (data.length) {
                    var newElements = data.css({ opacity: 0 });
                    content.html(newElements);
                    newElements.animate({ opacity: 1 });
                    loader.removeClass('post-no-more-posts').removeClass('post-loading-loader');
                    $('.after-post-component').addClass('hidden');
                    var post_count = $('.post-list').find('.post-box').length;
                    if (post_count < ppp) {
                        // do not show button
                    } else {
                        $('.load-more-posts').removeClass('hidden');
                    }
                } else {
                    loader.removeClass('post-loading-loader').addClass('post-no-more-posts');
                    $('.after-post-component').addClass('hidden');
                    $('.no-more-message').removeClass('hidden');
                }
            },
            error : function (errorResponse, textStatus, errorThrown) {
                loader.html($.parseJSON(errorResponse.responseText) + ' :: ' + textStatus + ' :: ' + errorThrown);
                console.log(errorResponse);
            },
        });
        return false;
    }*/

}(jQuery));
